.profileBadge {
  display: inline-block;
  font-size: 1rem;
  color: #7e7e7e;
  margin-left: 0.4rem;

  :global(.icon) {
    margin-left: 0.2rem;
    width: 1.5em;
    height: 1.5em;
    stroke-width: 2;
  }
}

